import React, { useState } from 'react'
import { Link } from 'gatsby'
import { IoIosSearch, IoIosClose } from 'react-icons/io'
import { DrawerProvider } from '../../../components/Drawer/DrawerContext'
import Menu from './Menu'
import MobileMenu from './MobileMenu'
import SearchContainer from '../../containers/SearchContainer/SearchContainer'
import HeaderWrapper, {
  NavbarWrapper,
  Logo,
  MenuWrapper,
  NavSearchButton,
  NavSearchWrapper,
  SearchCloseButton,
  NavSearchFromWrapper
} from './Navbar.style'
import LogoImage from '../../images/logo.png'
import ChessImage from '../../images/chess.png'

type NavbarProps = {
  className?: string
}

const MenuItems = [
  {
    label: 'Home',
    url: '/'
  },
  {
    label: 'Workshops',
    url: '/workshops'
  },
  {
    label: 'Speaking',
    url: '/conference-talks'
  },
  {
    label: 'Courses',
    url: '/courses'
  },
  {
    label: 'Live',
    url: '/live-with-tyler'
  },
  {
    label: 'Blog',
    url: '/blog'
  },
  {
    label: 'Newsletter',
    url: '/contact'
  }
]

const Navbar: React.FunctionComponent<NavbarProps> = ({
  className,
  ...props
}) => {
  const [state, setState] = useState({
    toggle: false,
    search: ''
  })

  const toggleHandle = () => {
    setState({
      ...state,
      toggle: !state.toggle
    })
  }

  // Add all classs to an array
  const addAllClasses = ['header']

  // className prop checking
  if (className) {
    addAllClasses.push(className)
  }

  return (
    <HeaderWrapper className={addAllClasses.join(' ')} {...props}>
      <NavbarWrapper className="navbar">
        <DrawerProvider>
          <MobileMenu items={MenuItems} logo={ChessImage} />
        </DrawerProvider>
        <Logo>
          <Link to="/">
            <img src={ChessImage} alt="logo" />
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>
        <MenuWrapper>
          <Menu items={MenuItems} />
        </MenuWrapper>
        <NavSearchButton
          type="button"
          aria-label="search"
          onClick={toggleHandle}
        >
          <IoIosSearch size="23px" />
        </NavSearchButton>
      </NavbarWrapper>

      <NavSearchWrapper className={state.toggle === true ? 'expand' : ''}>
        <NavSearchFromWrapper>
          <SearchContainer />
          <SearchCloseButton
            type="submit"
            aria-label="close"
            onClick={toggleHandle}
          >
            <IoIosClose />
          </SearchCloseButton>
        </NavSearchFromWrapper>
      </NavSearchWrapper>
    </HeaderWrapper>
  )
}

export default Navbar
