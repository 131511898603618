import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import Input from '../../../components/Input/Input'
import Button from '../../../components/Button/Button'
import {
  NewsletterWrapper,
  NewsletterInnerWrapper,
  NewsletterTitle,
  NewsletterDescription,
  NewsletterInputWrapper,
  ErrorMessage,
  SuccessMessage
} from './Newsletter.style'

type NewsletterProps = {}

const Newsletter: React.FunctionComponent<NewsletterProps> = ({ ...props }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [success, setSuccess] = useState()
  const [error, setError] = useState()
  const handleChange = (e: any) => {
    setEmail(e.target.value)
  }

  const handleNameChange = (e: any) => {
    setName(e.target.value)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    addToMailchimp(email, { FNAME: name })
      .then(({ msg, result }: any) => {
        if (result !== 'success') {
          throw msg
        }
        setSuccess(msg)
        setError('')
        setEmail('')
      })
      .catch((err: any) => {
        setError(err)
        setSuccess('')
        setEmail('')
      })
  }
  return (
    <NewsletterWrapper {...props}>
      <NewsletterInnerWrapper>
        <NewsletterTitle>
          I send out a newsletter... occasionally 🙂
        </NewsletterTitle>
        <NewsletterDescription>
          If you like my content then you can sign up to be notified of upcoming
          stuff first. Most of my letters will be centered around frontend
          stuff, i.e JavaScript, CSS, React, testing stuff.. Though I'm also
          passionate about some soft skill stuff, interviews, SQL, and other
          backendy things.
          <br />
          <br />
          No spam, I won't sell your info, and you can unsubscribe at any time.
        </NewsletterDescription>

        <NewsletterInputWrapper onSubmit={handleSubmit}>
          {success ? (
            <SuccessMessage>{success} 🙂</SuccessMessage>
          ) : (
            <>
              <Input
                type="text"
                name="name"
                placeholder="first name"
                onChange={handleNameChange}
                value={name}
                required
              />
              <Input
                type="email"
                name="email"
                placeholder="email"
                onChange={handleChange}
                value={email}
                required
              />
              <Button title="Subscribe" type="submit" />
            </>
          )}
        </NewsletterInputWrapper>
        {error && (
          <ErrorMessage
            dangerouslySetInnerHTML={{ __html: `<span>*</span>${error}` }}
          />
        )}
      </NewsletterInnerWrapper>
    </NewsletterWrapper>
  )
}

export default Newsletter
